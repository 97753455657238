@font-face {
    font-family: 'Arca Majora 3 Bold';
    src: url('./assets/fonts/ArcaMajora3-Bold.eot');
    src: url('./assets/fonts/ArcaMajora3-Bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/ArcaMajora3-Bold.woff2') format('woff2'),
        url('./assets/fonts/ArcaMajora3-Bold.woff') format('woff'),
        url('./assets/fonts/ArcaMajora3-Bold.ttf') format('truetype'),
        url('./assets/fonts/ArcaMajora3-Bold.svg#ArcaMajora3-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arca Majora 3';
    src: url('./assets/fonts/ArcaMajora3.eot');
    src: url('./assets/fonts/ArcaMajora3.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/ArcaMajora3.woff2') format('woff2'),
        url('./assets/fonts/ArcaMajora3.woff') format('woff'),
        url('./assets/fonts/ArcaMajora3.ttf') format('truetype'),
        url('./assets/fonts/ArcaMajora3.svg#ArcaMajora3') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

body{
    font-family:'Arca Majora 3' !important;
    background-color: rgb(247, 248, 249) !important;
    color: #505d75 !important;
}
b, strong{
    color: #505d75;
}
a, button{
    text-decoration: none !important;
    outline: none !important;
}
.control-label{
    color: #505d75;
    text-transform: uppercase;
}

a:hover,
button:hover,
a:active,
button:active,
a:focus,
button:focus{
    text-decoration: none !important;
    outline: none !important;
}

.container-full {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.footer-text a{
    color: #222731;
}
.footer-text a:hover{
    color: #505d75;
    text-decoration: underline !important;
}

.footer-link a{
    font-size: 16px;
    color: #222731;
    margin-bottom: 10px;
}
.footer-link a:hover{
    color: #505d75;
    text-decoration: underline !important;
}


.video-react {
    font-family:'Arca Majora 3' !important;
}

.content-app{
    background: #fff;
}
.thumb-movie__title{
    margin-top: 5px;
    color: #ffffff;
    font-size: 15px;
    font-weight: 700;
}
.thumb-movie-actions__watch{
    margin-top: 5px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.5px;
}
.thumb-movie__img{
    position: relative;
}
.thumb-movie__play {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 200px;
    background: rgba(0, 0, 0, 0.4);
    transition: all 0.3s;
    font-size: 26px;
    color: #fff;
    padding-top: 20px;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
}

.thumb-movie{
    margin-top: -80px;
    margin-bottom: 60px;
    background: #fff;
}

.thumb-movie-single{
    margin-top: 150px;
    margin-bottom: -80px;
}

.thumb-movie__actions{
    text-align: center;
    transition: all 0.3s;
    display: block;
}
.thumb-movie__actions:hover{
    transform: scale(1.06);
    margin-top: -10px;
}
.thumb-movie__actions:hover .thumb-movie__play{
    background: rgba(0, 0, 0, 0.15);
}

.thumb-movie__actions img {
    border-radius: 200px;
    width: 100%;
}

.thumb_event__play {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
    font-size: 32px;
    color: #fff;
    padding-top: 20px;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
    cursor: pointer;
}

.row-comment .divider-comment{
    border-bottom: 1px solid #d9dede;
    width: 100%;
    padding-top: 15px;
    margin-bottom: 15px;
}

.row-comment .divider-comment:last-child{
    border-bottom: 0 !important;
    padding-top: 0 !important;
    margin-bottom: 0 !important;
}
.v-phone img{
    height: 14px;
}

.v-phone .lang-option img{
    height: 14px;
    margin-right: 6px;
}

.v-phone .lang-arrow {
    margin-left: 4px;
    margin-top: -1px;
    color: #000;
}

.v-phone {
    width: 72px;
    text-align: left;
    position: absolute !important;
    right: 8px;
    top: 8px;
    z-index: 10;
}

.v-phone .lang-option {
    padding-left: 10px;
    margin-left: 24px;
    background: #dce7eb;
    width: 156px;
    padding-bottom: 4px;
    box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    z-index: -1;
    margin-top: -4px;
}

.v-phone .lang-option li {
    display: flex;
    margin-top: 3px;
    margin-bottom: 3px;
}
.v-phone .lang-option a {
    color: #424648 !important;
    text-decoration: none !important;
    cursor: pointer !important;
    font-size: 12px !important;
}
.v-phone .lang-option a:hover {
    color: #292c2e !important;
    text-decoration: underline !important;
}


.thumb-event__title{
    font-size: 22px;
    color: #fff;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
    font-weight: 700;
    padding-bottom: 10px;
}
.thumb-event-actions__watch{
    margin-top: 5px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.5px;
}
.thumb-event__img{
    position: relative;
}
.thumb-event__play {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
    font-size: 16px;
    color: #fff;
    padding-bottom: 10px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}

.thumb-event{
    margin-top: -390px;
    position: absolute;
}
.thumb-event__actions{
    text-align: center;
    transition: all 0.3s;
    display: block;
}
.thumb-event__actions:hover .thumb-event__play{
    background: rgba(0, 0, 0, 0);
}

.thumb-event__actions img {
    border-radius: 10px;
    width: 100%;
}

.thumb-event__actions__text{
    position: relative;
    width: 100%;
    height: 100%;
}

.thumb-event__text{
    position: absolute;
    bottom: 2px;
    left: 0;
    display: flex;
    flex-direction: column;
}

.thumb-event__text a:first-child{
    font-size: 16px;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    font-weight: 700;
}
.cover-bg {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.cover-bg.top {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;
}

.contain-bg {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.img-top{
    width: 100%;
    height: 50px;
}
.img-bottom{
    width: 100%;
}
.row-8{
    margin-left: 0px !important;
}
.logo{
    width: 400px !important;
}
.search{
    width: 400px !important;
}
.bg-miami{
    background-image: url('./assets/images/bg_top.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.bg-footer{
    background-image: url('./assets/images/bg_footer.jpg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    
}
.text-ice {
    color: #dce7eb !important;
}
.force-left{
    text-align: left !important;
}
.remove-mt{
    margin-top: -1px;
}
.countdown{
    font-size: 36px;
    margin-bottom: 30px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.countdown .days {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 30px;
}
.countdown .days span:first-child {
    font-size: 16px;
}

.footer-text{
    color: #1e1f21 !important;
}
.what-miami h2 {
    letter-spacing: 2px;
    color: #6c7477;
}
.what-miami p {
    font-size: 1.3rem;
    letter-spacing: 2px;
    line-height: 1.8rem;
    color: #6c7477;
}
.form-subscribe{
    background-color: #dce7eb;
    color: #6c7477;
    width: 100%;
    border: 0;
    border-radius: 100px;
    padding:10px 15px;
    margin-bottom: 15px;
    text-align: center;
}
.form-subscribe:focus,
.form-subscribe:active{
    background-color: #ffffff;
    outline: none;
}
textarea.form-subscribe{
    border-radius: 22px;
}
.btn-subscribe{
    background-color: transparent;
    color: #dce7eb;
    border: 0;
}

.btn-subscribe:hover,
.btn-subscribe:focus,
.btn-subscribe:active{
    color: #ffffff;
    outline: none;
}
.lang-menu{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.lang-content:hover .lang-option{
    display: block !important;
}
.lang-arrow {
    margin-left: 6px;
    margin-top: -1px;
}
.lang-content {
    margin-left: 25px;
    position: relative;
}
.lang-option{
    list-style: none;
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    padding-left: 0;
    padding-top: 30px;
}
.lang-menu,
.lang-option a{
    color: #dce7eb !important;
    text-decoration: none;
    cursor: pointer;
}
.lang-menu:hover,
.lang-option a:hover{
    color: #edf3f5 !important;
    text-decoration: underline !important;
}


.header {
	height: 150px;
    z-index: 1001;
    background-color: rgba(0, 0, 0, 0.2);
    background: linear-gradient(180deg, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0.4) 35%, rgba(0,0,0,0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 50px;
}
.container-full.pages {
    background: linear-gradient(180deg, #00000096 0%, #00000096 35%, #00000096 100%);
}
.carousel-banner-home .slider-wrapper:before{
    position: absolute;
    content: '';
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 35%, rgba(0, 0, 0, 0) 100%);
    top: 0;
    left: 0;
    width: 150px;
    height: 100%;
    z-index: 10;
}
.carousel-banner-home .slider-wrapper:after{
    position: absolute;
    content: '';
    background: linear-gradient(-90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 35%, rgba(0, 0, 0, 0) 100%);
    top: 0;
    right: 0;
    width: 150px;
    height: 100%;
    z-index: 10;
}

.btn-next,
.btn-prev{
	position: absolute;
	display: inline-block;
	z-index: 999;
	color: #fff;
	top: 50%;
	transform: translate(0, -50%);
	background: rgba(0, 0, 0, 0.3);
	width: 30px;
	height: 30px;
	border-radius: 100%;
	overflow: hidden;
	text-align: center
}

.btn-next:hover,
.btn-prev:hover {
	color: #fff;
	background: rgba(0, 0, 0, 0.3)
}

.btn-next i{
	padding: 4px;
	margin-left: 1px;
	font-size: 22px
}

.btn-prev i {
	padding: 4px;
	margin-left: -2px;
	font-size: 22px
}
.btn-next{
	right: 20px
}
.btn-prev {
	left: 20px
}



.btn-next,
.btn-prev{
    color: #fff;
	width: 60px;
    height: 100%;
    background: transparent;
}

.btn-next:hover,
.btn-prev:hover{
    color: #fff;
    background: transparent;
}
.btn-next {
    right: 0px;
    padding-right: 20px;
}

.btn-prev {
    left: 0px;
    padding-left: 20px;
}
.btn-next i,
.btn-prev i {
	padding: 19px;
}

.btn-prev,
.btn-next{
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-prev svg {
    margin-left: -3px;
}
.btn-next svg {
    margin-right: -3px;
}



.main-menu {
    position: relative;
}
.menu-action{
    margin-bottom: -18px;
    padding-bottom: 18px;
}
.menu-action:hover ~.menu-option,
.menu-option:hover{
    display: block !important;
}
.menu-option{
    list-style: none;
    display: none;
    position: absolute;
    left: 0;
    top: 44px;
    background: #eff2f5;
    padding: 15px;
    border-radius: 0 0 10px 10px;
    box-shadow: rgba(8, 11, 14, 0.06) 0px 0px 1px 0px, rgba(8, 11, 14, 0.1) 0px 6px 6px -1px;
    z-index: 10;
}
.main-menu .menu-action,
.main-menu .menu-option a{
    color: #6d87a0 !important;
    text-decoration: none;
    cursor: pointer;
}
.main-menu .menu-option li{
    padding-bottom: 10px !important;
}
.main-menu .menu-option li:last-child{
    padding-bottom: 0px !important;
}
.main-menu .menu-action:hover,
.main-menu .menu-option a:hover{
    color: #627b92 !important;
    text-decoration: underline !important;
}

.content-error {
    text-transform: uppercase;
    color: #bb4848;
    font-size: 14px;
}
.content-error  svg{
    vertical-align: sub;
}
.content-success {
    text-transform: uppercase;
    color: #529232;
    font-size: 14px;
}
.content-success  svg{
    vertical-align: sub;
}
.loading-sending{
    opacity: 0.4;
    pointer-events: none;
}
.social a{
    font-size: 26px;
    margin: 0 6px;
}
.contact a{
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 130px;
    margin: auto;
}
.social a,
.contact a{
    color: #dce7eb !important;
    text-decoration: none !important;
    cursor: pointer !important;
}
.social a:hover,
.contact a:hover{
    color: #ffffff !important;
    text-decoration: none !important;
}

.subscribe h5{
    text-transform: uppercase;
}

.link a,
.link{
    color: #dce7eb !important;
    text-decoration: none !important;
    cursor: pointer !important;
}
.link a:hover,
.link:hover{
    color: #dce7eb !important;
    text-decoration: underline !important;
}

.b-link{
    color: #0056b3 !important;
    text-decoration: none !important;
    cursor: pointer !important;
}
.b-link:hover{
    color: #1363b9 !important;
    text-decoration: underline !important;
}

@media (max-width: 1140px) {
    .logo {
        width: 300px !important;
    }
    .search{
        width: 300px !important;
    }
    .lang-content {
        right: 10px;
    }
}
.mobile-view{
    display: none !important;
}
.mobile-view-d{
    display: none !important;
}
.mobile-header{
    background-color: #000;
    color: #fff;
    height: 100px;
}
.menu-mobile{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eff2f5;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s;
}
.menu-mobile.opened{
    visibility: visible;
    opacity: 1;
}
.menu-close{
    position: absolute;
    left: 15px;
    top: 15px;
}

.menu-option-mobile{
    list-style: none;
    display: block;
    padding: 0 18%;
    text-align: center;
}

.menu-option-mobile a{
    color: #6d87a0 !important;
    text-decoration: none !important;
    cursor: pointer;
    font-size: 22px;
    line-height: 28px;
}
.menu-option-mobile li{
    padding-bottom: 25px !important;
}
.menu-option-mobile li:last-child{
    padding-bottom: 0px !important;
}
.menu-option-mobile a:active,
.menu-option-mobile a:focus{
    color: #6d87a0 !important;
    opacity: 0.4;
}
.menu-option-mobile li svg {
    vertical-align: top;
}
.li-divider{
    border-top: 1px solid #dfe3e7;
}
.cam-photo{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.04);
    transition: all 0.4s;
    border-radius: 100%;
    padding-bottom: 14px;
}
.cam-photo:hover{
    background-color: rgba(0, 0, 0, 0.14);}
/* MOBILE */
@media (max-width: 420px) {
    .logo {
        width: 240px !important;
    }
    .search{
        width: 300px !important;
    }
    .lang-content {
        right: 10px;
    }
    .thumb-movie__play {
        font-size: 15px;
        padding-top: 36px;
    }
    .thumb-movie{
        margin-top: 15px;
        margin-bottom: 0px;
    }
    .thumb-movie-single {
        margin-top: 20px;
        margin-bottom: -80px;
    }
    .mobile-hidden{
        display: none !important;
    }
    .mobile-view{
        display: block !important;
    }
    .mobile-view-d{
        display: flex !important;
    }
    .bg-footer{
        padding-bottom: 340px;
    }
    .img-top{
        width: 100%;
        height: 14px;
    }
    .img-bottom{
        width: 100%;
        height: 20px;
    }
    .custom-input.textarea{
        width: 100% !important;
    }

    a,
    button{
        transition: opacity 0.2s;
    }

    a:active,
    a:focus,
    button:active,
    button:focus{
        opacity: 0.4;
    }
}

.main-menu a{
    font-size: 16px;
    margin-right: 25px;
    color: #dce7eb !important;
    text-decoration: none !important;
    cursor: pointer !important;
    transition: all 0.2s;
}

.main-menu a.current:hover,
.main-menu a:hover{
    color: #fff !important;
    text-decoration: underline !important;
}

.main-menu a.current{
    color: #fff !important;
}

.no-underline:hover{
    text-decoration: none !important;
}

.menu-opacity img{
    transition: all 0.4s;
}
.menu-opacity:hover img{
    opacity: 0.8;
}

.menu-zoom{
    transition: all 0.4s;
}
.menu-zoom:hover{
    transform: scale(1.05);
}
.content-hidden{
    display: none;
}
.text-html p {
    line-height: 30px;
    font-size: 17px;
}
.w-25 {
	width: 25% !important
}

.w-50 {
	width: 50% !important
}

.w-75 {
	width: 75% !important
}

.w-100 {
	width: 100% !important
}

.h-25 {
	height: 25% !important
}

.h-50 {
	height: 50% !important
}

.h-75 {
	height: 75% !important
}

.h-100 {
	height: 100% !important
}

.mw-100 {
	max-width: 100% !important
}

.mh-100 {
	max-height: 100% !important
}

.m-0 {
	margin: 0 !important
}

.mt-0 {
	margin-top: 0 !important
}

.mr-0 {
	margin-right: 0 !important
}

.mb-0 {
	margin-bottom: 0 !important
}

.ml-0 {
	margin-left: 0 !important
}

.mx-0 {
	margin-right: 0 !important;
	margin-left: 0 !important
}

.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important
}

.m-1 {
	margin: 0.25rem !important
}

.mt-1 {
	margin-top: 0.25rem !important
}

.mr-1 {
	margin-right: 0.25rem !important
}

.mb-1 {
	margin-bottom: 0.25rem !important
}

.ml-1 {
	margin-left: 0.25rem !important
}

.mx-1 {
	margin-right: 0.25rem !important;
	margin-left: 0.25rem !important
}

.my-1 {
	margin-top: 0.25rem !important;
	margin-bottom: 0.25rem !important
}

.m-2 {
	margin: 0.5rem !important
}

.mt-2 {
	margin-top: 0.5rem !important
}

.mr-2 {
	margin-right: 0.5rem !important
}

.mb-2 {
	margin-bottom: 0.5rem !important
}

.ml-2 {
	margin-left: 0.5rem !important
}

.mx-2 {
	margin-right: 0.5rem !important;
	margin-left: 0.5rem !important
}

.my-2 {
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important
}

.m-3 {
	margin: 1rem !important
}

.mt-3 {
	margin-top: 1rem !important
}

.mr-3 {
	margin-right: 1rem !important
}

.mb-3 {
	margin-bottom: 1rem !important
}

.ml-3 {
	margin-left: 1rem !important
}

.mx-3 {
	margin-right: 1rem !important;
	margin-left: 1rem !important
}

.my-3 {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important
}

.m-4 {
	margin: 1.5rem !important
}

.mt-4 {
	margin-top: 1.5rem !important
}

.mr-4 {
	margin-right: 1.5rem !important
}

.mb-4 {
	margin-bottom: 1.5rem !important
}

.ml-4 {
	margin-left: 1.5rem !important
}

.mx-4 {
	margin-right: 1.5rem !important;
	margin-left: 1.5rem !important
}

.my-4 {
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important
}

.m-5 {
	margin: 3rem !important
}

.mt-5 {
	margin-top: 3rem !important
}

.mr-5 {
	margin-right: 3rem !important
}

.mb-5 {
	margin-bottom: 3rem !important
}

.ml-5 {
	margin-left: 3rem !important
}

.mx-5 {
	margin-right: 3rem !important;
	margin-left: 3rem !important
}

.my-5 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important
}

.p-0 {
	padding: 0 !important
}

.pt-0 {
	padding-top: 0 !important
}

.pr-0 {
	padding-right: 0 !important
}

.pb-0 {
	padding-bottom: 0 !important
}

.pl-0 {
	padding-left: 0 !important
}

.px-0 {
	padding-right: 0 !important;
	padding-left: 0 !important
}

.py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important
}

.p-1 {
	padding: 0.25rem !important
}

.pt-1 {
	padding-top: 0.25rem !important
}

.pr-1 {
	padding-right: 0.25rem !important
}

.pb-1 {
	padding-bottom: 0.25rem !important
}

.pl-1 {
	padding-left: 0.25rem !important
}

.px-1 {
	padding-right: 0.25rem !important;
	padding-left: 0.25rem !important
}

.py-1 {
	padding-top: 0.25rem !important;
	padding-bottom: 0.25rem !important
}

.p-2 {
	padding: 0.5rem !important
}

.pt-2 {
	padding-top: 0.5rem !important
}

.pr-2 {
	padding-right: 0.5rem !important
}

.pb-2 {
	padding-bottom: 0.5rem !important
}

.pl-2 {
	padding-left: 0.5rem !important
}

.px-2 {
	padding-right: 0.5rem !important;
	padding-left: 0.5rem !important
}

.py-2 {
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important
}

.p-3 {
	padding: 1rem !important
}

.pt-3 {
	padding-top: 1rem !important
}

.pr-3 {
	padding-right: 1rem !important
}

.pb-3 {
	padding-bottom: 1rem !important
}

.pl-3 {
	padding-left: 1rem !important
}

.px-3 {
	padding-right: 1rem !important;
	padding-left: 1rem !important
}

.py-3 {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important
}

.p-4 {
	padding: 1.5rem !important
}

.pt-4 {
	padding-top: 1.5rem !important
}

.pr-4 {
	padding-right: 1.5rem !important
}

.pb-4 {
	padding-bottom: 1.5rem !important
}

.pl-4 {
	padding-left: 1.5rem !important
}

.px-4 {
	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important
}

.py-4 {
	padding-top: 1.5rem !important;
	padding-bottom: 1.5rem !important
}

.p-5 {
	padding: 3rem !important
}

.pt-5 {
	padding-top: 3rem !important
}

.pr-5 {
	padding-right: 3rem !important
}

.pb-5 {
	padding-bottom: 3rem !important
}

.pl-5 {
	padding-left: 3rem !important
}

.px-5 {
	padding-right: 3rem !important;
	padding-left: 3rem !important
}

.py-5 {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important
}

.m-auto {
	margin: auto !important
}

.mt-auto {
	margin-top: auto !important
}

.mr-auto {
	margin-right: auto !important
}

.mb-auto {
	margin-bottom: auto !important
}

.ml-auto {
	margin-left: auto !important
}

.mx-auto {
	margin-right: auto !important;
	margin-left: auto !important
}

.my-auto {
	margin-top: auto !important;
	margin-bottom: auto !important
}

@media (min-width: 540px) {
	.m-sm-0 {
		margin: 0 !important
	}

	.mt-sm-0 {
		margin-top: 0 !important
	}

	.mr-sm-0 {
		margin-right: 0 !important
	}

	.mb-sm-0 {
		margin-bottom: 0 !important
	}

	.ml-sm-0 {
		margin-left: 0 !important
	}

	.mx-sm-0 {
		margin-right: 0 !important;
		margin-left: 0 !important
	}

	.my-sm-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important
	}

	.m-sm-1 {
		margin: 0.25rem !important
	}

	.mt-sm-1 {
		margin-top: 0.25rem !important
	}

	.mr-sm-1 {
		margin-right: 0.25rem !important
	}

	.mb-sm-1 {
		margin-bottom: 0.25rem !important
	}

	.ml-sm-1 {
		margin-left: 0.25rem !important
	}

	.mx-sm-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important
	}

	.my-sm-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important
	}

	.m-sm-2 {
		margin: 0.5rem !important
	}

	.mt-sm-2 {
		margin-top: 0.5rem !important
	}

	.mr-sm-2 {
		margin-right: 0.5rem !important
	}

	.mb-sm-2 {
		margin-bottom: 0.5rem !important
	}

	.ml-sm-2 {
		margin-left: 0.5rem !important
	}

	.mx-sm-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important
	}

	.my-sm-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important
	}

	.m-sm-3 {
		margin: 1rem !important
	}

	.mt-sm-3 {
		margin-top: 1rem !important
	}

	.mr-sm-3 {
		margin-right: 1rem !important
	}

	.mb-sm-3 {
		margin-bottom: 1rem !important
	}

	.ml-sm-3 {
		margin-left: 1rem !important
	}

	.mx-sm-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important
	}

	.my-sm-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important
	}

	.m-sm-4 {
		margin: 1.5rem !important
	}

	.mt-sm-4 {
		margin-top: 1.5rem !important
	}

	.mr-sm-4 {
		margin-right: 1.5rem !important
	}

	.mb-sm-4 {
		margin-bottom: 1.5rem !important
	}

	.ml-sm-4 {
		margin-left: 1.5rem !important
	}

	.mx-sm-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important
	}

	.my-sm-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important
	}

	.m-sm-5 {
		margin: 3rem !important
	}

	.mt-sm-5 {
		margin-top: 3rem !important
	}

	.mr-sm-5 {
		margin-right: 3rem !important
	}

	.mb-sm-5 {
		margin-bottom: 3rem !important
	}

	.ml-sm-5 {
		margin-left: 3rem !important
	}

	.mx-sm-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important
	}

	.my-sm-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important
	}

	.p-sm-0 {
		padding: 0 !important
	}

	.pt-sm-0 {
		padding-top: 0 !important
	}

	.pr-sm-0 {
		padding-right: 0 !important
	}

	.pb-sm-0 {
		padding-bottom: 0 !important
	}

	.pl-sm-0 {
		padding-left: 0 !important
	}

	.px-sm-0 {
		padding-right: 0 !important;
		padding-left: 0 !important
	}

	.py-sm-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important
	}

	.p-sm-1 {
		padding: 0.25rem !important
	}

	.pt-sm-1 {
		padding-top: 0.25rem !important
	}

	.pr-sm-1 {
		padding-right: 0.25rem !important
	}

	.pb-sm-1 {
		padding-bottom: 0.25rem !important
	}

	.pl-sm-1 {
		padding-left: 0.25rem !important
	}

	.px-sm-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important
	}

	.py-sm-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important
	}

	.p-sm-2 {
		padding: 0.5rem !important
	}

	.pt-sm-2 {
		padding-top: 0.5rem !important
	}

	.pr-sm-2 {
		padding-right: 0.5rem !important
	}

	.pb-sm-2 {
		padding-bottom: 0.5rem !important
	}

	.pl-sm-2 {
		padding-left: 0.5rem !important
	}

	.px-sm-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important
	}

	.py-sm-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important
	}

	.p-sm-3 {
		padding: 1rem !important
	}

	.pt-sm-3 {
		padding-top: 1rem !important
	}

	.pr-sm-3 {
		padding-right: 1rem !important
	}

	.pb-sm-3 {
		padding-bottom: 1rem !important
	}

	.pl-sm-3 {
		padding-left: 1rem !important
	}

	.px-sm-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important
	}

	.py-sm-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important
	}

	.p-sm-4 {
		padding: 1.5rem !important
	}

	.pt-sm-4 {
		padding-top: 1.5rem !important
	}

	.pr-sm-4 {
		padding-right: 1.5rem !important
	}

	.pb-sm-4 {
		padding-bottom: 1.5rem !important
	}

	.pl-sm-4 {
		padding-left: 1.5rem !important
	}

	.px-sm-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important
	}

	.py-sm-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important
	}

	.p-sm-5 {
		padding: 3rem !important
	}

	.pt-sm-5 {
		padding-top: 3rem !important
	}

	.pr-sm-5 {
		padding-right: 3rem !important
	}

	.pb-sm-5 {
		padding-bottom: 3rem !important
	}

	.pl-sm-5 {
		padding-left: 3rem !important
	}

	.px-sm-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important
	}

	.py-sm-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important
	}

	.m-sm-auto {
		margin: auto !important
	}

	.mt-sm-auto {
		margin-top: auto !important
	}

	.mr-sm-auto {
		margin-right: auto !important
	}

	.mb-sm-auto {
		margin-bottom: auto !important
	}

	.ml-sm-auto {
		margin-left: auto !important
	}

	.mx-sm-auto {
		margin-right: auto !important;
		margin-left: auto !important
	}

	.my-sm-auto {
		margin-top: auto !important;
		margin-bottom: auto !important
	}
}

@media (min-width: 800px) {
	.m-md-0 {
		margin: 0 !important
	}

	.mt-md-0 {
		margin-top: 0 !important
	}

	.mr-md-0 {
		margin-right: 0 !important
	}

	.mb-md-0 {
		margin-bottom: 0 !important
	}

	.ml-md-0 {
		margin-left: 0 !important
	}

	.mx-md-0 {
		margin-right: 0 !important;
		margin-left: 0 !important
	}

	.my-md-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important
	}

	.m-md-1 {
		margin: 0.25rem !important
	}

	.mt-md-1 {
		margin-top: 0.25rem !important
	}

	.mr-md-1 {
		margin-right: 0.25rem !important
	}

	.mb-md-1 {
		margin-bottom: 0.25rem !important
	}

	.ml-md-1 {
		margin-left: 0.25rem !important
	}

	.mx-md-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important
	}

	.my-md-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important
	}

	.m-md-2 {
		margin: 0.5rem !important
	}

	.mt-md-2 {
		margin-top: 0.5rem !important
	}

	.mr-md-2 {
		margin-right: 0.5rem !important
	}

	.mb-md-2 {
		margin-bottom: 0.5rem !important
	}

	.ml-md-2 {
		margin-left: 0.5rem !important
	}

	.mx-md-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important
	}

	.my-md-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important
	}

	.m-md-3 {
		margin: 1rem !important
	}

	.mt-md-3 {
		margin-top: 1rem !important
	}

	.mr-md-3 {
		margin-right: 1rem !important
	}

	.mb-md-3 {
		margin-bottom: 1rem !important
	}

	.ml-md-3 {
		margin-left: 1rem !important
	}

	.mx-md-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important
	}

	.my-md-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important
	}

	.m-md-4 {
		margin: 1.5rem !important
	}

	.mt-md-4 {
		margin-top: 1.5rem !important
	}

	.mr-md-4 {
		margin-right: 1.5rem !important
	}

	.mb-md-4 {
		margin-bottom: 1.5rem !important
	}

	.ml-md-4 {
		margin-left: 1.5rem !important
	}

	.mx-md-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important
	}

	.my-md-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important
	}

	.m-md-5 {
		margin: 3rem !important
	}

	.mt-md-5 {
		margin-top: 3rem !important
	}

	.mr-md-5 {
		margin-right: 3rem !important
	}

	.mb-md-5 {
		margin-bottom: 3rem !important
	}

	.ml-md-5 {
		margin-left: 3rem !important
	}

	.mx-md-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important
	}

	.my-md-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important
	}

	.p-md-0 {
		padding: 0 !important
	}

	.pt-md-0 {
		padding-top: 0 !important
	}

	.pr-md-0 {
		padding-right: 0 !important
	}

	.pb-md-0 {
		padding-bottom: 0 !important
	}

	.pl-md-0 {
		padding-left: 0 !important
	}

	.px-md-0 {
		padding-right: 0 !important;
		padding-left: 0 !important
	}

	.py-md-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important
	}

	.p-md-1 {
		padding: 0.25rem !important
	}

	.pt-md-1 {
		padding-top: 0.25rem !important
	}

	.pr-md-1 {
		padding-right: 0.25rem !important
	}

	.pb-md-1 {
		padding-bottom: 0.25rem !important
	}

	.pl-md-1 {
		padding-left: 0.25rem !important
	}

	.px-md-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important
	}

	.py-md-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important
	}

	.p-md-2 {
		padding: 0.5rem !important
	}

	.pt-md-2 {
		padding-top: 0.5rem !important
	}

	.pr-md-2 {
		padding-right: 0.5rem !important
	}

	.pb-md-2 {
		padding-bottom: 0.5rem !important
	}

	.pl-md-2 {
		padding-left: 0.5rem !important
	}

	.px-md-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important
	}

	.py-md-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important
	}

	.p-md-3 {
		padding: 1rem !important
	}

	.pt-md-3 {
		padding-top: 1rem !important
	}

	.pr-md-3 {
		padding-right: 1rem !important
	}

	.pb-md-3 {
		padding-bottom: 1rem !important
	}

	.pl-md-3 {
		padding-left: 1rem !important
	}

	.px-md-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important
	}

	.py-md-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important
	}

	.p-md-4 {
		padding: 1.5rem !important
	}

	.pt-md-4 {
		padding-top: 1.5rem !important
	}

	.pr-md-4 {
		padding-right: 1.5rem !important
	}

	.pb-md-4 {
		padding-bottom: 1.5rem !important
	}

	.pl-md-4 {
		padding-left: 1.5rem !important
	}

	.px-md-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important
	}

	.py-md-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important
	}

	.p-md-5 {
		padding: 3rem !important
	}

	.pt-md-5 {
		padding-top: 3rem !important
	}

	.pr-md-5 {
		padding-right: 3rem !important
	}

	.pb-md-5 {
		padding-bottom: 3rem !important
	}

	.pl-md-5 {
		padding-left: 3rem !important
	}

	.px-md-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important
	}

	.py-md-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important
	}

	.m-md-auto {
		margin: auto !important
	}

	.mt-md-auto {
		margin-top: auto !important
	}

	.mr-md-auto {
		margin-right: auto !important
	}

	.mb-md-auto {
		margin-bottom: auto !important
	}

	.ml-md-auto {
		margin-left: auto !important
	}

	.mx-md-auto {
		margin-right: auto !important;
		margin-left: auto !important
	}

	.my-md-auto {
		margin-top: auto !important;
		margin-bottom: auto !important
	}
}

@media (min-width: 1024px) {
	.m-lg-0 {
		margin: 0 !important
	}

	.mt-lg-0 {
		margin-top: 0 !important
	}

	.mr-lg-0 {
		margin-right: 0 !important
	}

	.mb-lg-0 {
		margin-bottom: 0 !important
	}

	.ml-lg-0 {
		margin-left: 0 !important
	}

	.mx-lg-0 {
		margin-right: 0 !important;
		margin-left: 0 !important
	}

	.my-lg-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important
	}

	.m-lg-1 {
		margin: 0.25rem !important
	}

	.mt-lg-1 {
		margin-top: 0.25rem !important
	}

	.mr-lg-1 {
		margin-right: 0.25rem !important
	}

	.mb-lg-1 {
		margin-bottom: 0.25rem !important
	}

	.ml-lg-1 {
		margin-left: 0.25rem !important
	}

	.mx-lg-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important
	}

	.my-lg-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important
	}

	.m-lg-2 {
		margin: 0.5rem !important
	}

	.mt-lg-2 {
		margin-top: 0.5rem !important
	}

	.mr-lg-2 {
		margin-right: 0.5rem !important
	}

	.mb-lg-2 {
		margin-bottom: 0.5rem !important
	}

	.ml-lg-2 {
		margin-left: 0.5rem !important
	}

	.mx-lg-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important
	}

	.my-lg-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important
	}

	.m-lg-3 {
		margin: 1rem !important
	}

	.mt-lg-3 {
		margin-top: 1rem !important
	}

	.mr-lg-3 {
		margin-right: 1rem !important
	}

	.mb-lg-3 {
		margin-bottom: 1rem !important
	}

	.ml-lg-3 {
		margin-left: 1rem !important
	}

	.mx-lg-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important
	}

	.my-lg-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important
	}

	.m-lg-4 {
		margin: 1.5rem !important
	}

	.mt-lg-4 {
		margin-top: 1.5rem !important
	}

	.mr-lg-4 {
		margin-right: 1.5rem !important
	}

	.mb-lg-4 {
		margin-bottom: 1.5rem !important
	}

	.ml-lg-4 {
		margin-left: 1.5rem !important
	}

	.mx-lg-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important
	}

	.my-lg-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important
	}

	.m-lg-5 {
		margin: 3rem !important
	}

	.mt-lg-5 {
		margin-top: 3rem !important
	}

	.mr-lg-5 {
		margin-right: 3rem !important
	}

	.mb-lg-5 {
		margin-bottom: 3rem !important
	}

	.ml-lg-5 {
		margin-left: 3rem !important
	}

	.mx-lg-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important
	}

	.my-lg-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important
	}

	.p-lg-0 {
		padding: 0 !important
	}

	.pt-lg-0 {
		padding-top: 0 !important
	}

	.pr-lg-0 {
		padding-right: 0 !important
	}

	.pb-lg-0 {
		padding-bottom: 0 !important
	}

	.pl-lg-0 {
		padding-left: 0 !important
	}

	.px-lg-0 {
		padding-right: 0 !important;
		padding-left: 0 !important
	}

	.py-lg-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important
	}

	.p-lg-1 {
		padding: 0.25rem !important
	}

	.pt-lg-1 {
		padding-top: 0.25rem !important
	}

	.pr-lg-1 {
		padding-right: 0.25rem !important
	}

	.pb-lg-1 {
		padding-bottom: 0.25rem !important
	}

	.pl-lg-1 {
		padding-left: 0.25rem !important
	}

	.px-lg-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important
	}

	.py-lg-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important
	}

	.p-lg-2 {
		padding: 0.5rem !important
	}

	.pt-lg-2 {
		padding-top: 0.5rem !important
	}

	.pr-lg-2 {
		padding-right: 0.5rem !important
	}

	.pb-lg-2 {
		padding-bottom: 0.5rem !important
	}

	.pl-lg-2 {
		padding-left: 0.5rem !important
	}

	.px-lg-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important
	}

	.py-lg-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important
	}

	.p-lg-3 {
		padding: 1rem !important
	}

	.pt-lg-3 {
		padding-top: 1rem !important
	}

	.pr-lg-3 {
		padding-right: 1rem !important
	}

	.pb-lg-3 {
		padding-bottom: 1rem !important
	}

	.pl-lg-3 {
		padding-left: 1rem !important
	}

	.px-lg-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important
	}

	.py-lg-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important
	}

	.p-lg-4 {
		padding: 1.5rem !important
	}

	.pt-lg-4 {
		padding-top: 1.5rem !important
	}

	.pr-lg-4 {
		padding-right: 1.5rem !important
	}

	.pb-lg-4 {
		padding-bottom: 1.5rem !important
	}

	.pl-lg-4 {
		padding-left: 1.5rem !important
	}

	.px-lg-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important
	}

	.py-lg-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important
	}

	.p-lg-5 {
		padding: 3rem !important
	}

	.pt-lg-5 {
		padding-top: 3rem !important
	}

	.pr-lg-5 {
		padding-right: 3rem !important
	}

	.pb-lg-5 {
		padding-bottom: 3rem !important
	}

	.pl-lg-5 {
		padding-left: 3rem !important
	}

	.px-lg-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important
	}

	.py-lg-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important
	}

	.m-lg-auto {
		margin: auto !important
	}

	.mt-lg-auto {
		margin-top: auto !important
	}

	.mr-lg-auto {
		margin-right: auto !important
	}

	.mb-lg-auto {
		margin-bottom: auto !important
	}

	.ml-lg-auto {
		margin-left: auto !important
	}

	.mx-lg-auto {
		margin-right: auto !important;
		margin-left: auto !important
	}

	.my-lg-auto {
		margin-top: auto !important;
		margin-bottom: auto !important
	}
}

@media (min-width: 1199px) {
	.m-xl-0 {
		margin: 0 !important
	}

	.mt-xl-0 {
		margin-top: 0 !important
	}

	.mr-xl-0 {
		margin-right: 0 !important
	}

	.mb-xl-0 {
		margin-bottom: 0 !important
	}

	.ml-xl-0 {
		margin-left: 0 !important
	}

	.mx-xl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important
	}

	.my-xl-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important
	}

	.m-xl-1 {
		margin: 0.25rem !important
	}

	.mt-xl-1 {
		margin-top: 0.25rem !important
	}

	.mr-xl-1 {
		margin-right: 0.25rem !important
	}

	.mb-xl-1 {
		margin-bottom: 0.25rem !important
	}

	.ml-xl-1 {
		margin-left: 0.25rem !important
	}

	.mx-xl-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important
	}

	.my-xl-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important
	}

	.m-xl-2 {
		margin: 0.5rem !important
	}

	.mt-xl-2 {
		margin-top: 0.5rem !important
	}

	.mr-xl-2 {
		margin-right: 0.5rem !important
	}

	.mb-xl-2 {
		margin-bottom: 0.5rem !important
	}

	.ml-xl-2 {
		margin-left: 0.5rem !important
	}

	.mx-xl-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important
	}

	.my-xl-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important
	}

	.m-xl-3 {
		margin: 1rem !important
	}

	.mt-xl-3 {
		margin-top: 1rem !important
	}

	.mr-xl-3 {
		margin-right: 1rem !important
	}

	.mb-xl-3 {
		margin-bottom: 1rem !important
	}

	.ml-xl-3 {
		margin-left: 1rem !important
	}

	.mx-xl-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important
	}

	.my-xl-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important
	}

	.m-xl-4 {
		margin: 1.5rem !important
	}

	.mt-xl-4 {
		margin-top: 1.5rem !important
	}

	.mr-xl-4 {
		margin-right: 1.5rem !important
	}

	.mb-xl-4 {
		margin-bottom: 1.5rem !important
	}

	.ml-xl-4 {
		margin-left: 1.5rem !important
	}

	.mx-xl-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important
	}

	.my-xl-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important
	}

	.m-xl-5 {
		margin: 3rem !important
	}

	.mt-xl-5 {
		margin-top: 3rem !important
	}

	.mr-xl-5 {
		margin-right: 3rem !important
	}

	.mb-xl-5 {
		margin-bottom: 3rem !important
	}

	.ml-xl-5 {
		margin-left: 3rem !important
	}

	.mx-xl-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important
	}

	.my-xl-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important
	}

	.p-xl-0 {
		padding: 0 !important
	}

	.pt-xl-0 {
		padding-top: 0 !important
	}

	.pr-xl-0 {
		padding-right: 0 !important
	}

	.pb-xl-0 {
		padding-bottom: 0 !important
	}

	.pl-xl-0 {
		padding-left: 0 !important
	}

	.px-xl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important
	}

	.py-xl-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important
	}

	.p-xl-1 {
		padding: 0.25rem !important
	}

	.pt-xl-1 {
		padding-top: 0.25rem !important
	}

	.pr-xl-1 {
		padding-right: 0.25rem !important
	}

	.pb-xl-1 {
		padding-bottom: 0.25rem !important
	}

	.pl-xl-1 {
		padding-left: 0.25rem !important
	}

	.px-xl-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important
	}

	.py-xl-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important
	}

	.p-xl-2 {
		padding: 0.5rem !important
	}

	.pt-xl-2 {
		padding-top: 0.5rem !important
	}

	.pr-xl-2 {
		padding-right: 0.5rem !important
	}

	.pb-xl-2 {
		padding-bottom: 0.5rem !important
	}

	.pl-xl-2 {
		padding-left: 0.5rem !important
	}

	.px-xl-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important
	}

	.py-xl-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important
	}

	.p-xl-3 {
		padding: 1rem !important
	}

	.pt-xl-3 {
		padding-top: 1rem !important
	}

	.pr-xl-3 {
		padding-right: 1rem !important
	}

	.pb-xl-3 {
		padding-bottom: 1rem !important
	}

	.pl-xl-3 {
		padding-left: 1rem !important
	}

	.px-xl-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important
	}

	.py-xl-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important
	}

	.p-xl-4 {
		padding: 1.5rem !important
	}

	.pt-xl-4 {
		padding-top: 1.5rem !important
	}

	.pr-xl-4 {
		padding-right: 1.5rem !important
	}

	.pb-xl-4 {
		padding-bottom: 1.5rem !important
	}

	.pl-xl-4 {
		padding-left: 1.5rem !important
	}

	.px-xl-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important
	}

	.py-xl-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important
	}

	.p-xl-5 {
		padding: 3rem !important
	}

	.pt-xl-5 {
		padding-top: 3rem !important
	}

	.pr-xl-5 {
		padding-right: 3rem !important
	}

	.pb-xl-5 {
		padding-bottom: 3rem !important
	}

	.pl-xl-5 {
		padding-left: 3rem !important
	}

	.px-xl-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important
	}

	.py-xl-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important
	}

	.m-xl-auto {
		margin: auto !important
	}

	.mt-xl-auto {
		margin-top: auto !important
	}

	.mr-xl-auto {
		margin-right: auto !important
	}

	.mb-xl-auto {
		margin-bottom: auto !important
	}

	.ml-xl-auto {
		margin-left: auto !important
	}

	.mx-xl-auto {
		margin-right: auto !important;
		margin-left: auto !important
	}

	.my-xl-auto {
		margin-top: auto !important;
		margin-bottom: auto !important
	}
}

@media (min-width: 1400px) {
	.m-xxl-0 {
		margin: 0 !important
	}

	.mt-xxl-0 {
		margin-top: 0 !important
	}

	.mr-xxl-0 {
		margin-right: 0 !important
	}

	.mb-xxl-0 {
		margin-bottom: 0 !important
	}

	.ml-xxl-0 {
		margin-left: 0 !important
	}

	.mx-xxl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important
	}

	.my-xxl-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important
	}

	.m-xxl-1 {
		margin: 0.25rem !important
	}

	.mt-xxl-1 {
		margin-top: 0.25rem !important
	}

	.mr-xxl-1 {
		margin-right: 0.25rem !important
	}

	.mb-xxl-1 {
		margin-bottom: 0.25rem !important
	}

	.ml-xxl-1 {
		margin-left: 0.25rem !important
	}

	.mx-xxl-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important
	}

	.my-xxl-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important
	}

	.m-xxl-2 {
		margin: 0.5rem !important
	}

	.mt-xxl-2 {
		margin-top: 0.5rem !important
	}

	.mr-xxl-2 {
		margin-right: 0.5rem !important
	}

	.mb-xxl-2 {
		margin-bottom: 0.5rem !important
	}

	.ml-xxl-2 {
		margin-left: 0.5rem !important
	}

	.mx-xxl-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important
	}

	.my-xxl-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important
	}

	.m-xxl-3 {
		margin: 1rem !important
	}

	.mt-xxl-3 {
		margin-top: 1rem !important
	}

	.mr-xxl-3 {
		margin-right: 1rem !important
	}

	.mb-xxl-3 {
		margin-bottom: 1rem !important
	}

	.ml-xxl-3 {
		margin-left: 1rem !important
	}

	.mx-xxl-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important
	}

	.my-xxl-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important
	}

	.m-xxl-4 {
		margin: 1.5rem !important
	}

	.mt-xxl-4 {
		margin-top: 1.5rem !important
	}

	.mr-xxl-4 {
		margin-right: 1.5rem !important
	}

	.mb-xxl-4 {
		margin-bottom: 1.5rem !important
	}

	.ml-xxl-4 {
		margin-left: 1.5rem !important
	}

	.mx-xxl-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important
	}

	.my-xxl-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important
	}

	.m-xxl-5 {
		margin: 3rem !important
	}

	.mt-xxl-5 {
		margin-top: 3rem !important
	}

	.mr-xxl-5 {
		margin-right: 3rem !important
	}

	.mb-xxl-5 {
		margin-bottom: 3rem !important
	}

	.ml-xxl-5 {
		margin-left: 3rem !important
	}

	.mx-xxl-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important
	}

	.my-xxl-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important
	}

	.p-xxl-0 {
		padding: 0 !important
	}

	.pt-xxl-0 {
		padding-top: 0 !important
	}

	.pr-xxl-0 {
		padding-right: 0 !important
	}

	.pb-xxl-0 {
		padding-bottom: 0 !important
	}

	.pl-xxl-0 {
		padding-left: 0 !important
	}

	.px-xxl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important
	}

	.py-xxl-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important
	}

	.p-xxl-1 {
		padding: 0.25rem !important
	}

	.pt-xxl-1 {
		padding-top: 0.25rem !important
	}

	.pr-xxl-1 {
		padding-right: 0.25rem !important
	}

	.pb-xxl-1 {
		padding-bottom: 0.25rem !important
	}

	.pl-xxl-1 {
		padding-left: 0.25rem !important
	}

	.px-xxl-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important
	}

	.py-xxl-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important
	}

	.p-xxl-2 {
		padding: 0.5rem !important
	}

	.pt-xxl-2 {
		padding-top: 0.5rem !important
	}

	.pr-xxl-2 {
		padding-right: 0.5rem !important
	}

	.pb-xxl-2 {
		padding-bottom: 0.5rem !important
	}

	.pl-xxl-2 {
		padding-left: 0.5rem !important
	}

	.px-xxl-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important
	}

	.py-xxl-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important
	}

	.p-xxl-3 {
		padding: 1rem !important
	}

	.pt-xxl-3 {
		padding-top: 1rem !important
	}

	.pr-xxl-3 {
		padding-right: 1rem !important
	}

	.pb-xxl-3 {
		padding-bottom: 1rem !important
	}

	.pl-xxl-3 {
		padding-left: 1rem !important
	}

	.px-xxl-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important
	}

	.py-xxl-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important
	}

	.p-xxl-4 {
		padding: 1.5rem !important
	}

	.pt-xxl-4 {
		padding-top: 1.5rem !important
	}

	.pr-xxl-4 {
		padding-right: 1.5rem !important
	}

	.pb-xxl-4 {
		padding-bottom: 1.5rem !important
	}

	.pl-xxl-4 {
		padding-left: 1.5rem !important
	}

	.px-xxl-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important
	}

	.py-xxl-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important
	}

	.p-xxl-5 {
		padding: 3rem !important
	}

	.pt-xxl-5 {
		padding-top: 3rem !important
	}

	.pr-xxl-5 {
		padding-right: 3rem !important
	}

	.pb-xxl-5 {
		padding-bottom: 3rem !important
	}

	.pl-xxl-5 {
		padding-left: 3rem !important
	}

	.px-xxl-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important
	}

	.py-xxl-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important
	}

	.m-xxl-auto {
		margin: auto !important
	}

	.mt-xxl-auto {
		margin-top: auto !important
	}

	.mr-xxl-auto {
		margin-right: auto !important
	}

	.mb-xxl-auto {
		margin-bottom: auto !important
	}

	.ml-xxl-auto {
		margin-left: auto !important
	}

	.mx-xxl-auto {
		margin-right: auto !important;
		margin-left: auto !important
	}

	.my-xxl-auto {
		margin-top: auto !important;
		margin-bottom: auto !important
	}
}

.mt0 {
	margin-top: 0px
}

.mt5 {
	margin-top: 5px
}

.mt10 {
	margin-top: 10px
}

.mt15 {
	margin-top: 15px
}

.mt20 {
	margin-top: 20px
}

.mt25 {
	margin-top: 25px
}

.mt30 {
	margin-top: 30px
}

.mt35 {
	margin-top: 35px
}

.mt40 {
	margin-top: 40px
}

.mt45 {
	margin-top: 45px
}

.mb0 {
	margin-bottom: 0px
}

.mb5 {
	margin-bottom: 5px
}

.mb10 {
	margin-bottom: 10px
}

.mb15 {
	margin-bottom: 15px
}

.mb20 {
	margin-bottom: 20px
}

.mb25 {
	margin-bottom: 25px
}

.mb30 {
	margin-bottom: 30px
}

.mb35 {
	margin-bottom: 35px
}

.mb40 {
	margin-bottom: 40px
}

.mb45 {
	margin-bottom: 45px
}

.ml0 {
	margin-left: 0px
}

.ml5 {
	margin-left: 5px
}

.ml10 {
	margin-left: 10px
}

.ml15 {
	margin-left: 15px
}

.ml20 {
	margin-left: 20px
}

.ml25 {
	margin-left: 25px
}

.ml30 {
	margin-left: 30px
}

.ml35 {
	margin-left: 35px
}

.ml40 {
	margin-left: 40px
}

.ml45 {
	margin-left: 45px
}

.mr0 {
	margin-right: 0px
}

.mr5 {
	margin-right: 5px
}

.mr10 {
	margin-right: 10px
}

.mr15 {
	margin-right: 15px
}

.mr20 {
	margin-right: 20px
}

.mr25 {
	margin-right: 25px
}

.mr30 {
	margin-right: 30px
}

.mr35 {
	margin-right: 35px
}

.mr40 {
	margin-right: 40px
}

.mr45 {
	margin-right: 45px
}
.btn_all{
    background: #eff2f5;
    color: #6d87a0;
    padding: 14px 25px 12px 25px;
    border-radius: 8px;
    text-transform: uppercase;
    transition: all 0.4s;
    font-size: 14px;
    box-shadow: rgba(8, 11, 14, 0.06) 0px 0px 1px 0px, rgba(8, 11, 14, 0.1) 0px 3px 3px -1px;
}
.btn_all svg {
    vertical-align: top;
}
.btn_all:hover{
    background: #e2e6eb;
    color: #627b92;
}

.img-fluid{
    width: 100%;
}

.radius-round{
    border-radius: 6px;
}

.content-post p{
    padding-bottom: 15px !important;
}

.content-image{
    margin-bottom: 20px;
}
.content-image img{
    width: 100%;
    border-radius: 6px;
}
.content-text{
    margin-bottom: 20px;
}
.content-text p:last-child{
    padding-bottom: 0px !important;
}
.content-video{
    margin-bottom: 20px;
}
.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
    margin-right: 1px;
    border-radius: 6px;
}

.embed-container iframe,.embed-container object,.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}


.btn-checkbox,
.btn-checkbox:hover{
    color: #999;
    font-size: 14px;
    font-weight: 400;
    margin-right: 10px;
}
.btn-checkbox svg{
    vertical-align: top;
    margin-right: 2px;
}
.btn-checkbox.checked{
    color: #505d75;
}
.loader-btn{
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.loader-btn .spinner-content {
    position: relative;
    margin-right: 45px;
    margin-top: -11px;
    margin-left: -8px;
}
.loader-btn .spinner-text {
    font-weight: 600;
}
.loading-sending{
    opacity: 0.4;
    pointer-events: none;
}


.bt-spinner {
	width: 46px;
	height: 46px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -23px;
	margin-top: -23px;
	border-radius: 50%;
	background-color: transparent;
	border: 3px solid #ddd;
	border-top: 3px solid #505d75;
	-webkit-animation: 1s spin linear infinite;
	animation: .6s spin linear infinite;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05)
}

.bt-spinner.mini {
	width: 26px;
	height: 26px;
	margin-left: 8px;
	margin-top: -8px
}

@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.custom-input{
    border-width: 1px;
    border-style: solid;
    border-color: rgb(201, 206, 214);
    border-image: initial;
    padding-left: 12px;
    padding-top: 12px;
    border-radius: 8px;
    line-height: 24px;
    font-size: 14px;
    transition: all 0.4s ease-in-out 0s;
    font-weight: 500;
    color: rgb(0, 0, 0);
    background: rgb(255, 255, 255);
    height: 2.5rem;
    padding-bottom: 10px;
    padding-right: 40px;
    width: 100%;
    box-sizing: border-box;
}

.custom-input:focus {
    border-color: rgb(138, 148, 166);
}


.custom-input.donate {
    width:180px;
    text-align: center;
    padding-right: 12px;
}

.custom-input.textarea{
    width: 60%;
    height: 120px;
}